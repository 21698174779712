import styled, { css } from "styled-components"

export const linkCss = css`
  text-decoration: none;
  color: inherit;
  :hover {
    text-decoration: underline;
  }
`
const Anchor = styled("a").attrs(() => ({
  target: "_blank",
  rel: "noopener noreferrer",
}))`
  ${linkCss};
`
export default Anchor
