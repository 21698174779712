import React from "react"
import { Link as BaseLink } from "gatsby"
import styled from "styled-components"

import Anchor from "./Anchor"
import { devices } from "../styles/media"
import { ThemeContext } from "../components/ThemeManager"

function Navigation() {
  const setTheme = React.useContext(ThemeContext)
  return (
    <Nav>
      <NavLeft>
        <Link to="/">
          <Header>thchia.com</Header>
        </Link>
        <ContactLink href="mailto:thomas@cactustree.io">
          &#8212;&#8212;&emsp;Contact
        </ContactLink>
      </NavLeft>
      <NavRight>
        {/* #TODO: Find a place to put this */}
        {/* <button onClick={setTheme}>Theme</button> */}
        <SocialList>
          <SocialItem>
            <BlogLink to="/blog" activeClassName="blog-active" partiallyActive>
              Blog
            </BlogLink>
          </SocialItem>
          {/* <SocialItem>
            <Anchor href="https://github.com/thchia">Github</Anchor>
          </SocialItem> */}
          <SocialItem>
            <Anchor href="https://www.linkedin.com/in/thomas-chia-a0a5678a">
              LinkedIn
            </Anchor>
          </SocialItem>
          {/* <SocialItem>
            <Anchor href="https://twitter.com/th__chia">Twitter</Anchor>
          </SocialItem> */}
        </SocialList>
      </NavRight>
    </Nav>
  )
}

export default Navigation

const Nav = styled("nav")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  margin-bottom: 90px;
  @media ${devices.tablet} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3rem;
    section {
      width: 100%;
      text-align: center;
      justify-content: space-between;
    }
  }
`
const NavLeft = styled("section")`
  display: flex;
  align-items: center;
`
const NavRight = styled("section")`
  @media ${devices.tablet} {
    border-bottom: ${({ theme }) => `solid 1px ${theme.secondary3}`};
  }
`
const Header = styled("h2")`
  font-family: Playfair Display;
  margin: 0px;
  font-size: 1.2rem;
`
const SocialList = styled("ul")`
  display: inline-block;
  list-style-type: none;
  margin: 10px 0px;
  padding: 0px 10px;
`
const SocialItem = styled("li")`
  display: inline-block;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.secondary1};
  margin: 0px 10px;
  padding: 0px;
  text-align: right;
`
const ContactLink = styled(Anchor)`
  background-color: ${({ theme }) => theme.contrast};
  color: ${({ theme }) => theme.background};
  padding: 5px 25px;
  font-size: 0.8rem;
  margin-left: 20px;
`
const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.contrast};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
const BlogLink = styled(BaseLink)`
  color: ${({ theme }) => theme.secondary1};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  &.blog-active {
    text-decoration: underline;
  }
`
