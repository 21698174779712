import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components"

import SEO from "../components/seo"
import Navigation from "./Navigation"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <SEO title="Home" />
      <GlobalStyle />
      <Navigation />
      <main>{children}</main>
      <footer>
        <FooterP>
          &copy; {new Date().getFullYear()} Thomas Chia&ensp;
          <span role="img" aria-label="Singapore Flag">
            🇸🇬
          </span>
        </FooterP>
      </footer>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
  }
`

const FooterP = styled("p")`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.secondary2};
  text-align: center;
`
